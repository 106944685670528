html {
    font-size: 62.5%;
    font-family: "Kanit", sans-serif !important;
    /* background-color: #121212; */
}

* {
    /* Text rendering */
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    font-family: "Kanit", sans-serif !important;
}

.dangrek-regular {
    font-family: "Dangrek", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

.kanit-thin {
    font-family: "Kanit", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.kanit-extralight {
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.kanit-light {
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.kanit-regular {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.kanit-medium {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.kanit-semibold {
    font-family: "Kanit", sans-serif !important;
    font-weight: 600;
    font-style: normal;
}

.kanit-bold {
    font-family: "Kanit", sans-serif !important;
    font-weight: 700;
    font-style: normal;
}

.kanit-extrabold {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.kanit-black {
    font-family: "Kanit", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.kanit-thin-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.kanit-extralight-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.kanit-light-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.kanit-regular-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.kanit-medium-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.kanit-semibold-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.kanit-bold-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.kanit-extrabold-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.kanit-black-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 900;
    font-style: italic;
}


.flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}


.p-4 {
    padding: 4px;
}

.p-6 {
    padding: 6px;
}

.p-8 {
    padding: 8px;
}

.p-16 {
    padding: 16px;
}

.pt-4 {
    padding-top: 4px;
}

.pb-4 {
    padding-bottom: 4px;
}

.pt-8 {
    padding-top: 8px;
}

.pt-16 {
    padding-top: 16px;
}

.ptlr-8 {
    padding: 8px 8px 0px 8px
}

.plr-2 {
    padding-left: 2px;
    padding-right: 2px;
}

.plr-4 {
    padding-left: 4px;
    padding-right: 4px;
}

.plr-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.plr-16 {
    padding-left: 16px;
    padding-right: 16px;
}

.plr-24 {
    padding-left: 24px;
    padding-right: 24px;
}

.plr-32 {
    padding-left: 32px;
    padding-right: 32px;
}

.plr-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.plr-48 {
    padding-left: 48px;
    padding-right: 48px;
}

.align-items-center {
    align-items: center;
}

.text-align-center {
    text-align: center;
}

.font-item-title {
    font-size: 10px !important;
    line-height: 14.95px !important;
    font-weight: 400 !important;
    font-family: "Kanit", sans-serif !important;
}

.badge-total {
    background-color: #0074EC;
    width: 48px;
    height: 22px;
    border-radius: 3px;
}

.font-header {
    font-size: 24px !important;
    color: #000000 !important;
    font-weight: 600 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.font-subheader {
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: 400 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.font-tag {
    font-size: 16px !important;
    color: #1302FF !important;
    font-weight: 400 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
    text-decoration: underline;
}

.font-item-count {
    font-size: 22px !important;
    line-height: 32.89px !important;
    font-weight: 500 !important;
    color: #FFFFFF !important;
    font-family: "Kanit", sans-serif !important;
}

.font-item-sold {
    font-size: 22px !important;
    color: #4A4A4A !important;
    font-weight: 500 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.font-list {
    font-family: "Kanit", sans-serif !important;
    font-weight: 500 !important;
    white-space: nowrap;
    font-size: 20px !important;

}

.font-payment {
    font-size: 20px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.img-prompay {
    width: 169px;
    height: 54px;
}

.border-prompay {
    border: 2px solid #003D6A;
    border-radius: 5px;
}

.img-visa {
    width: 64px;
    height: 54px;
}

.img-mastercard {
    width: 60px;
    height: 36px;
}

.img-jcb {
    width: 49px;
    height: 38px;
}

.img-union {
    width: 46px;
    height: 29px;
}

.swal2-popup {
    font-size: 1.5rem !important;
}

.font-dialog-title{
    font-size: 24px;
    font-weight: 700;
    color: #222222;
}

.font-dialog{
    font-size: 24px;
    font-weight: 400;
    color: #222222;
}

.font-dialog-detail{
    font-size: 16px;
    font-weight: 400;
    color: #222222;
}



